<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Starter component
 */
export default {
  page: {
    title: "Paramètres des Prestations",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      sections: [
        {
          title: "Prestations Restauration",
          settings: [
            {
              title: "Service",
              route: "base.settings.prestations.restauration.service.index",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content",
              icon: "fas fa-clipboard-list ",
            },

            {
              title: "Type Repas",
              route: "base.settings.prestations.restauration.typeRepas.index",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content",
              icon: "bx bx-restaurant",
            },

            {
              title: "Lieu Repas",
              route: "base.settings.prestations.restauration.lieuRepas.index",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content",
              icon: "bx bxs-map-pin ",
            },
            {
              title: "Periode Repas",
              route: "base.settings.prestations.restauration.periodeRepas.index",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content",
              icon: "far fa-calendar-alt",
            },
          ],
        },

        {
          title: "Prestations Hébergement",
          settings: [
            {
              title: "Types de Hébergement",
              route: "base.settings.prestations.hebergement.type.index",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content",
              icon: "bx bx-hotel",
            }
          ],
        },

        {
          title: "Prestations Maintenance",
          settings: [
            {
              title: "Types de maintenance",
              route: "base.settings.prestations.maintenance.type.index",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content",
              icon: "fas fa-tools",
            }
          ],
        },

        {
          title: "Prestations Escpace Vert",
          settings: [
            {
              title: "Types escpace vert",
              route: "base.settings.prestations.escpaceVert.type.index",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content",
              icon: "bx bxs-tree",
            }
          ],
        },

        {
          title: "Prestations Opérations 3D",
          settings: [
            {
              title: "Types Opérations 3D",
              route: "base.settings.prestations.operations3D.type.index",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content",
              icon: "mdi mdi-rotate-3d",
            }
          ],
        },

        {
          title: "Prestations Diverses",
          settings: [
            {
              title: "Types Diverses",
              route: "base.settings.prestations.diverses.type.index",
              description:
                "This is a wider card with supporting text below as a natural lead-in to additional content",
              icon: "fas fa-globe-africa",
            }
          ],
        },

      ],

      items: [
        
        {
          text: "Paramètres Des Prestations" ,
          active: true,
        },
      
      ],
    };
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="settingsPage" v-for="section in sections" v-bind:key="section.title">
      <h4 class="mb-0 font-size-18">{{ section.title }}</h4> <br>
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-2" v-for="settingSection in section.settings" v-bind:key="settingSection.title">
          <router-link :to="{ name: settingSection.route }">
          <b-card
            header-class="bg-transparent border-primary"
            class="border border-primary text-center"
          >
            <template v-slot:header>
              <h5 class="my-0 text-primary">
                <i class="settingIcon" :class="settingSection.icon"></i>
              </h5>
            </template>
            <a>{{ settingSection.title }}</a>
          </b-card>
          </router-link>
        </div>
        <div class="col-lg-1"></div>
      </div>
      <hr>
    </div>
  </Layout>
</template>
